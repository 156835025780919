body {
  background-image: url('../src/images/7404883_3648832.jpg'); /* Replace 'your-image.jpg' with your image path */
  background-repeat: no-repeat;           /* Prevent image repetition */
  background-attachment: fixed;           /* Keep the background fixed during scrolling */  
  background-size: cover;                 /* Scale the image to cover the entire container */
  background-position: center;            /* Center the image within the container */
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  border: 2px;
  margin: 40px 30px 20px; /*top left&right bottom */
  /* margin: 20px; */
  border-radius: 20px;
  box-shadow: 3px 5px 30px;
  width: auto;
  height: auto;
  backdrop-filter: blur(10px);  /* Key property for the blur effect */
  -webkit-backdrop-filter: blur(10px); /* Support for older Safari browsers */
  background-color: rgba(255, 255, 255, 0.4); /* Semi-transparent background */
  border-radius: 10px; /* Optional rounded corners */
  padding: 20px;  
}

.flex-column{
  margin-left: 30px;
  margin-right: 30px;
  padding: 10px ;

}

.main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.heading{
  /* backdrop-filter: blur(10px);  
  -webkit-backdrop-filter: blur(10px);  */
  background-color: rgba(255, 255, 255, 0.847); 
  border-radius: 37%; 
  padding: 20px;  
  margin: 20px 60px 0px;
}

#submitButton{
  margin: 20px 0px 10px;
  padding: 20px;
  background-color: rgb(0, 156, 96);
  border-color: rgb(234, 234, 234);
  box-shadow: 3px 5px 30px;
}